const config = {
  apiKey: "AIzaSyBqxSnBv1fnOI78t5cGPdchbS1YcWkLwjU",
  authDomain: "safety-app-proto-illumine.firebaseapp.com",
  databaseURL: "https://safety-app-proto-illumine.firebaseio.com",
  projectId: "safety-app-proto-illumine",
  storageBucket: "safety-app-proto-illumine.appspot.com",
  messagingSenderId: "681565459786",
  appId: "1:681565459786:web:efa1ea2991f276ac3b5db3",
  measurementId: "G-982PQJK4T4"
};

export default config;