import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react'

import thunk from 'redux-thunk';
import reducers from "./containers/combinedReducer";
import RouterComponent from "./Router";
import './styles/App.css';

const persistConfig = {
  key: 'safety-recognition-31-oct',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(persistedReducer, applyMiddleware(thunk));
let persistor = persistStore(store)
function App() {
  return <Provider store={ store }>
    <PersistGate loading={"Loading"} persistor={persistor}>
      <RouterComponent />
    </PersistGate>
  </Provider>;
}

export default App;
