import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';

import { hydrate, fetch } from "../redux/dataActions";
class setProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { id: "" };
    if (!Object.keys(this.props.data.settings).length) { this.props.hydrate("settings") };
    this.handleChange = name => event => {
      this.setState({ [name]: event.target.value })
    };
  }

  render() {
    // let { clientLogo } = this.props.data.settings;
    // console.log(this.props.data.user);
    return (
      <div className="pg-cntr">
        {<img style={{marginTop: "2rem"}} src={"/tata_logo.png"} width={100} height={"auto"} alt="client-logo" />}
        <form noValidate autoComplete="off">
          <div className="text-field-cntr">
            <TextField
              id="id"
              label="RFP ID"
              value={this.state.id}
              onChange={this.handleChange('id')}
              margin="normal"
              classes={{ root: "employee-id-input" }}
              fullWidth
            />
          </div>
          <div className="err-msg">{this.props.data.errMsg}</div>
          <div className="btn-cntr">
            <Button
              variant="outlined"
              size="small"
              onClick={(ev) => { if(this.state.id!=="") {this.props.fetch("user", `${this.state.id}`)} }}>
              Login
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({ data: state.data });
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ hydrate, fetch }, dispatch);
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(setProfile);