export const setParams = ({target, value}) => {
  return dispatch => {
    dispatch({ type: `SET_${target.toUpperCase()}`, payload: value })
  };
}

export const finishReport = ()=>{
  return dispatch=>{ dispatch({ type: "RESET"}) }
}

export const logout = ()=>{
  return dispatch=>{ dispatch({ type: "LOGOUT" }) }
}