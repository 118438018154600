import { combineReducers } from 'redux'
import dataReducers from "../containers/redux/dataReducers";
import appReducers from "../containers/redux/appStateReducers";

let reducers = {
  data: dataReducers,
  app: appReducers
};

export default combineReducers(reducers)
// export default reducers;