import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../components/Header";
import { setParams } from "../redux/appStateActions";

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
class NearMiss extends React.Component {
  constructor(props) {
    super(props);    
    this.categoryId = props.match.params.nmcId;
    this.missIds = Object.keys(props.data.nearMissCategories[this.categoryId].associatedNearMisses);
  }
  render() {
    console.log(this.props.app);
    let isHindi = this.props.app.hindi;
    return <div className="pg-cntr">
      <div style={{ width: "100%" }}>
        <Header hindi={this.props.app.hindi} back={()=>this.props.history.push("/nearMiss")} toggleEvent={this.props.setParams} />
        <div className="pg-cntr-no-header">
          <div style={{margin:"1rem 0rem"}}>Select an option which best represents the near miss you wish to report</div>
          {this.missIds.map(id=>{
            let miss = this.props.data.nearMiss[id];
            return <div onClick={(ev) => { this.props.history.push(`/nearMiss/${this.categoryId}/${id}`)}} className="nm-category-cntr" key={id} style={{ order: miss.order || 0 }} >
              <div className="nm-category-title">{isHindi ? miss["title-hn"] : miss.title}</div>
              <ChevronRightIcon />
            </div>
          })}
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({ data: state.data, app: state.app });
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ setParams }, dispatch);
}
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(NearMiss);
