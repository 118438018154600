import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { hydrate, postReport, loadFile } from "../redux/dataActions";
import { setParams } from "../redux/appStateActions";
import Header from "../../components/Header";
import Webcam from "react-webcam";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class TakePicture extends React.Component {
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef();
    this.captureImage = (ev) => {
      this.props.setParams({ target: "image", value: this.webcamRef.current.getScreenshot() });
    }
    this.onDrop = (picture) => {
      this.props.loadFile(picture[0], picture[0].name)
      this.props.setParams({ target: "image", value: picture });
    }
  }


  render() {
    const { innerWidth: width, innerHeight: height } = window;
    let isHindi = this.props.app.hindi;
    let mediaDimension = width - 32;
    return <div className="pg-cntr">
      <Header hindi={this.props.app.hindi} toggleEvent={this.props.setParams} back={()=>this.props.history.push("/signals")}/>
      <div className="pg-cntr-no-header">
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
          <div>
            {/* <div className="header-bar" onClick={()=>this.props.history.push("/signals")}>
                  <ChevronLeftIcon /> <span>{isHindi ? "पीछे" : "Back"}</span>
            </div> */}
            {this.props.app.signal === "newSignal" ? null : <div className="signal-description">
              {this.props.data.signals[this.props.app.signal] ? (isHindi ? this.props.data.signals[this.props.app.signal]["title-hn"] : this.props.data.signals[this.props.app.signal].title) : "loading..."}
            </div>}
            <div className="camera-cntr">
              {this.props.app.image ? <img alt="media" width={mediaDimension} height={mediaDimension} src={this.props.app.image} /> : <Webcam
                onUserMedia={(el) => this.setState({ cameraMsg: isHindi ? "रिपोर्ट को विस्तृत करने के लिए एक तस्वीर जोड़ें|" : "Click a picture to report" })}
                onUserMediaError={(el) => this.setState({ cameraMsg: JSON.stringify(el) })}
                audio={false}
                height={mediaDimension}
                ref={this.webcamRef}
                screenshotFormat="image/jpeg"
                width={mediaDimension}
                videoConstraints={{
                  width: mediaDimension,
                  height: mediaDimension,
                  facingMode: "environment"
                }}
              />}
              <div className="action-btn-cntr camera-action">
                {this.props.app.image
                  ? <div onClick={() => { this.props.setParams({ target: "image", value: null }) }}>{isHindi ? "ठीक न हो तो कैंसिल करें" : "Take a different Picture"}</div>
                  : <PhotoCameraIcon onClick={this.captureImage} />}
              </div>
            </div>
            <div className="comment-cntr" onClick={() => { this.props.setParams({ target: "commentBoxActive", value: true }) }}>
              {this.props.app.userComment || "Add a comment"}
            </div>

            <div className="action-btn-cntr" style={{}}>
              <Button variant="outlined" onClick={(ev) => { this.props.postReport(this.props.app); this.props.history.push("/report") }}>
                {isHindi ? "रिपोर्ट करें" : "Submit"}
              </Button>
            </div>
          </div>
        </Slide>
      </div>
      <Dialog classes={{ root: "usercomment-root" }} fullScreen open={this.props.app.commentBoxActive || false} TransitionComponent={Transition} onClose={() => this.props.setParams({ target: "commentBoxActive", value: false })}>
        <div style={{ padding: "2rem" }}>
          <form style={{ marginTop: "0px" }}>
            <TextField
              autoFocus={true}
              id="comment-signal"
              label="Add a comment"
              multiline
              defaultValue={this.props.app.userComment}
              margin="normal"
              rowsMax={6}
              fullWidth={true}
              className="text-area"
              onChange={(ev) => { this.setState({ tempComment: ev.target.value }) }}
            />
          </form>
          <div className="action-btn-cntr" style={{ justifyContent: "space-around" }}>
            <Button onClick={(ev) => { this.props.setParams({ target: "commentBoxActive", value: false }) }}>
              {isHindi ? "रद्द करें" : "Cancel"}
            </Button>

            <Button onClick={(ev) => {
              this.props.setParams({ target: "userComment", value: this.state.tempComment });
              this.props.setParams({ target: "commentBoxActive", value: false });
            }}>
              {isHindi ? "रिपोर्ट करें" : "Submit"}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  }
}

const mapStateToProps = state => ({ data: state.data, app: state.app });

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ hydrate, setParams, postReport, loadFile }, dispatch);
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(TakePicture);