import firebase from "firebase/app";
import 'firebase/database';
import 'firebase/storage';
import firebaseConfig from "../../configs/firebase";
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const storage = firebase.storage();
export const loadFile = (file, name)=>{
  return dispatch => {
    let ref = storage.ref(`newSignals/${name}`);
    console.log(ref.fullPath);
    ref.put(file).then(function(snapshot) {
      snapshot.ref.getDownloadURL().then(function(downloadURL) {
        dispatch({type:"SET_IMAGE", payload: downloadURL})
      });
      console.log('Uploaded a blob or file!');
    });
  }
}

export const setLocation = (AllLocations) => {
  return new Promise((resolve, reject) => {
    let locations = [];
    let showPosition = (position) => {
      let currentLocation = { long: position.coords.longitude, lat: position.coords.latitude };
      locations = Object.keys(AllLocations).map(locationId => {
        let location = AllLocations[locationId];
        location.id = locationId;
        location.distance = distance(location.latitude, location.longitude, currentLocation.lat, currentLocation.long, "K");
        return location;
      });
      locations = locations.sort(function (a, b) {
        var x = a.distance; var y = b.distance;
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
      resolve(locations[0]);
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, null, { enableHighAccuracy: true });
    } else {
      reject(null);
    }
  });
}

export const hydrate = (target) => {
  return dispatch => {
    firebase.database().ref(`/${target}`).once('value')
      .then(snapshot => {
        let data = snapshot.val();
        dispatch({ type: `SET_ALL_${target.toUpperCase()}`, payload: data });
        if (target === "locations") {
          setLocation(snapshot.val())
            .then(location => dispatch({ type: `SET_LOCATION`, payload: location }))
            .catch(el=>dispatch({ type: `SET_LOCATION`, payload: el }));
        }
      });
  };
};

export const fetch = (target, id) => {
  return dispatch => {
    if(sanityCheck(`/${target}/${id}`)){
    firebase.database().ref(`/${target}/${id}`).once('value')
      .then(snapshot => {
        let data = snapshot.val();
        if(target==="user"&&data){data.id = id}
        dispatch({ type: `SET_${target.toUpperCase()}`, payload: data });
        if (!snapshot.val()) {
          setTimeout(() => {
            dispatch({ type: "SET_ERROR", payload: null })
          }, 1000)
        }
      });
    } else {
      console.log("sanity chk failed");
      dispatch({ type: "SET_ERROR", payload: "Invalid String" })
    }
  };
}

function sanityCheck(str){
  return str.indexOf(".")===-1&&str.indexOf("#")===-1&&str.indexOf("$")===-1&&str.indexOf("[")===-1&&str.indexOf("]")===-1
};

export const postReport = (data) => {
  return dispatch => {
    let report = {
      userId: data.user.id,
      userName: data.user.name,
      location: data.location.id,
      event: data.event,
      signal: data.signal,
      userComment: data.userComment||null,
      image: data.image||null,
      timestamp: Date.now()
    }
    var newPostRef = firebase.database().ref("reports").push();
    var postId = newPostRef.key;
    firebase.database().ref(`reports/${postId}`).update(report)
    .then(el=>{ dispatch({ type: `REPORT_SENT`, payload: postId }) });
  }
}

export const postNearMiss = (data) => {
  return dispatch => {
    let report = {
      userId: data.user.id,
      userName: data.user.name,
      location: data.location.id,
      nearMissCategory: data.nearMissCategory,
      nearMiss: data.nearMiss,
      userComment: data.userComment||null,
      image: data.image||null,
      timestamp: Date.now()
    }
    console.log(report);
    var newPostRef = firebase.database().ref("reports").push();
    var postId = newPostRef.key;
    firebase.database().ref(`nearMissesReported/${postId}`).update(report)
    .then(el=>{ dispatch({ type: `REPORT_SENT`, payload: postId }) });
  }
}

export const fetchUserReports = (userId) =>  {
  return dispatch => {
    firebase.database().ref(`/reports/`).orderByChild('userId').equalTo(userId).on('value', snapshot=>{
      dispatch({type:"USER_REPORTS", payload: snapshot.val()});
    })
  }
}




function distance(lat1, lon1, lat2, lon2, unit) {
  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") { dist = dist * 1.609344 }
    if (unit === "N") { dist = dist * 0.8684 }
    return dist;
  }
}