const INITIAL_STATE = {
  location: {},
  event: null,
  signal: null,
  user: {},
  showSignals: false,
  showConfirmation: false,
  image: null,
  onReportPage: null,
  reportId: null,
  hindi: true,
  userReports: {},
  userComment: null,
  commentBoxActive: false,
  nearMissCategory: null,
  nearMiss: null
};

export default (state = INITIAL_STATE, action) => {
  let _user = state.user; let _hindi = state.hindi;
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "SET_LOCATION":
      return { ...state, location: action.payload }
    case "SET_ROLE":
      return { ...state, role: action.payload }
    case "SET_EVENT":
      return { ...state, event: action.payload }
    case "SET_SIGNAL":
      return { ...state, signal: action.payload }
    case "SET_SHOWSIGNALS":
      return { ...state, showSignals: action.payload }
    case "SET_SHOWCONFIRMATION":
      return { ...state, showConfirmation: action.payload }
    case "SET_IMAGE":
      return { ...state, image: action.payload }
    case "SET_HINDI":
      return { ...state, hindi: action.payload }
    case "SET_USERCOMMENT":
      return { ...state, userComment: action.payload }
    case "SET_COMMENTBOXACTIVE":
      return { ...state, commentBoxActive: action.payload }
    case "SET_NEARMISSCATEGORY":
      return { ...state, nearMissCategory: action.payload }
    case "SET_NEARMISS":
      return { ...state, nearMiss: action.payload }    
    case "REPORT_SENT":
      return { ...INITIAL_STATE, onReportPage: true, reportId: action.payload, user:_user, hindi: _hindi}
    case "SET_USER":
      if (action.payload) { return { ...state, user: action.payload } }
      return state;
    case "USER_REPORTS":
      return { ...state, userReports: action.payload};
    case "RESET":
      return {...INITIAL_STATE, user:_user, hindi: _hindi}
    case "LOGOUT":
      return {...INITIAL_STATE, hindi: _hindi}
    default:
      return state;
  }
}