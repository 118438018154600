import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../components/Header";
import EventList from "../../components/EventList";
import { fetchUserReports } from "../redux/dataActions";
import { setParams } from "../redux/appStateActions";

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = { userLogs: [] };
  }

  render() {
    return (<div className="pg-cntr">
      <Header hindi={this.props.app.hindi} toggleEvent={this.props.setParams} />
      <div className="pg-cntr-no-header">
        <div style={{fontWeight:"bold", textTransform:"capitalize"}}>My Reports</div>
        <EventList reports={this.props.app.userReports} />
      </div>
    </div>)
  }

  componentDidMount() { this.props.fetchUserReports(this.props.data.user.id) }
}

const mapStateToProps = state => ({ data: state.data, app: state.app });
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ fetchUserReports, setParams }, dispatch);
}
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(History);