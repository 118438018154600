import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { hydrate, postReport, loadFile } from "../redux/dataActions";
import { setParams } from "../redux/appStateActions";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Header from "../../components/Header";

class setVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { menuOpen: false, anchorEl: null, cameraMsg: "Checking your Camera...", tempComment: null };
    if (!Object.keys(this.props.data.locations).length) { this.props.hydrate("locations") };
    if (!Object.keys(this.props.data.roles).length) { this.props.hydrate("roles") };
    if (!(this.props.app.location && Object.keys(this.props.app.location).length)) { this.props.hydrate("locations") };
    if (!(this.props.data.vehicleEvents && Object.keys(this.props.data.vehicleEvents).length)) { this.props.hydrate("vehicleEvents") };
    if (!(this.props.data.signals && Object.keys(this.props.data.signals).length)) { this.props.hydrate("signals") };

    this.handleClick = (ev) => {
      this.setState({ menuOpen: true, anchorEl: ev.currentTarget });
    }
    this.menuOpen = (ev) => {
      this.setState({ ...this.state, menuOpen: true });
    }
    this.handleClose = (id) => {
      this.props.data.locations[id].id = id;
      this.props.setParams({ target: "location", value: this.props.data.locations[id] });
      this.setState({ ...this.state, menuOpen: false });
    }
    this.switchVehicleEvents = (evid) => {
      this.props.setParams({ target: "event", value: evid });
      this.props.setParams({ target: "showSignals", value: true });
    }

    this.hideConfirmation = () => {
      this.props.setParams({ target: "showConfirmation", value: false });
    }
    this.webcamRef = React.createRef();
    this.captureImage = (ev) => {
      this.props.setParams({ target: "image", value: this.webcamRef.current.getScreenshot() });
    }
    this.onDrop = (picture) => {
      this.props.loadFile(picture[0], picture[0].name)
      this.props.setParams({ target: "image", value: picture });
    }
  }

  componentDidMount() {
    this.props.hydrate("settings");
    // this.props.hydrate("locations");
    this.props.hydrate("roles");
    this.props.hydrate("eventsCategory");
    this.props.hydrate("vehicleEvents");
    this.props.hydrate("signals");
  }

  render() {
    let locations = this.props.data.locations;
    let location = this.props.app.location || {};
    let isHindi = this.props.app.hindi;
    // let role = this.props.data.roles[this.props.data.user.defaultRole];
    let associatedEvents = (location.id && locations[location.id]) ? locations[location.id].associatedEvents : {};
    let eventsCategory = this.props.data.eventsCategory;
    return <div className="pg-cntr">
      <div style={{ width: "100%" }}>
        <Header hindi={this.props.app.hindi} toggleEvent={this.props.setParams} back={() => this.props.history.push("/setLocation")} />
        <div className="pg-cntr-no-header">
          <div className="user-profile-intro section-header">
            {isHindi ? null : <span>At </span>}<span className="location-menu" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>{isHindi ? location["title-hn"] : location.title}</span>{isHindi ? <span> में</span> : null}
            <Menu
              id="location-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={this.state.menuOpen}
              onClose={this.handleClose}
            >
              {Object.keys(locations).map((id, i) => {
                let el = locations[id];
                return <MenuItem onClick={(ev) => this.handleClose(id)} value={id} key={id}>
                  {isHindi ? el["title-hn"] : el.title}
                </MenuItem>
              })}
            </Menu>
          </div>
          <div style={{ fontWeight: 500 }}>{isHindi ? "खतरे को रिपोर्ट करने के लिए सबसे प्रासंगिक संकेतों को चुनें |" : "I am seeing a potential safety hazard in the:"}</div>
          <div className="options-cntr vehicle-events-cntr">
            {/* {Object.keys(eventsCategory).map(ecId => {
              let eventCategory = eventsCategory[ecId];
              eventCategory.associatedEvents = eventCategory.associatedEvents || {};
              let commonEvents = intersect(Object.keys(eventCategory.associatedEvents), Object.keys(associatedEvents || {}));
              return (commonEvents.length
                ? <div className="event-category-cntr" key={ecId} style={{ order: eventCategory.order }}>
                  <div className="event-category-title">{isHindi ? eventCategory["title-hn"] : eventCategory.title}</div>
                  <div className="event-cntr">
                    <div className="category-icon"><img alt="event-cat" src={eventCategory.icon} /></div>
                    <div className="events">{commonEvents.map((eventId, i) => {
                      let event = this.props.data.vehicleEvents[eventId] || {};
                      return <div key={eventId} value={eventId} className="event-blk" onClick={(ev) => { this.switchVehicleEvents(eventId); this.props.history.push("/signals") }}>
                        <div className="event-text"> {isHindi ? event["title-hn"] : event.recognitionTrigger} </div>
                        <ChevronRightIcon />
                      </div>
                    })}</div>
                  </div>
                </div>
                : null);
            })} */}

            {Object.keys(associatedEvents).map((eventId, i) => {
              let event = this.props.data.vehicleEvents[eventId] || {};
              return <div key={eventId} value={eventId} className="event-blk" onClick={(ev) => { this.switchVehicleEvents(eventId); this.props.history.push("/signals") }}>
                <div className="event-cntr">
                  <div className="event-icon" style={{width:"72px"}}><img width={56} height={56} src={event.icon}/></div>
                  <div className="event-text" style={{marginLeft: "1rem"}}> {isHindi ? event["title-hn"] : event.recognitionTrigger} </div>
                </div>
                <ChevronRightIcon />
              </div>
            })}
          </div>
        </div>
      </div>
    </div>
  }
}

function intersect(a, b) {
  let t;
  if (b.length > a.length) { t = b; b = a; a = t; } // indexOf to loop over shorter
  return a.filter(function (e) {
    return b.indexOf(e) > -1;
  });
}

const mapStateToProps = state => ({ data: state.data, app: state.app });

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ hydrate, setParams, postReport, loadFile }, dispatch);
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(setVehicle);