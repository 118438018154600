const INITIAL_STATE = {
  locations: {},
  settings: {},
  roles:{},
  user: {},
  eventsCategory: {},
  vehicleEvents: {},
  signals: {},
  errMsg: null,
  nearMissCategories: null,
  nearMiss: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "SET_ALL_LOCATIONS":
      return { ...state, locations: action.payload }
    case "SET_ALL_SETTINGS":
      return { ...state, settings: action.payload }
    case "SET_ALL_ROLES":
      return { ...state, roles: action.payload }
    case "SET_ALL_EVENTSCATEGORY":
      return { ...state, eventsCategory: action.payload }
    case "SET_ALL_VEHICLEEVENTS":
        return { ...state, vehicleEvents: action.payload }
    case "SET_ALL_SIGNALS":
        return { ...state, signals: action.payload }
    case "SET_ALL_NEARMISSCATEGORIES":
        return { ...state, nearMissCategories: action.payload }
    case "SET_ALL_NEARMISS":
        return { ...state, nearMiss: action.payload }
    case "SET_USER":
      if (action.payload) { return { ...state, user: action.payload } }
      return { ...state, errMsg: "no such user" };
    case "SET_ERROR":
      if (action.payload) { return { ...state, user: action.payload } }
      return { ...state, errMsg: action.payload };
    case "LOGOUT":
      return {...INITIAL_STATE}
    default:
      return state;
  }
}