import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setParams } from "../redux/appStateActions";
import { finishReport } from "../redux/appStateActions";
import Header from "../../components/Header";
import Button from '@material-ui/core/Button';

class Report extends React.Component {
  render() {
    console.log(this.props);
    let isHindi = this.props.app.hindi;
    return <div className="pg-cntr">
      <Header hindi={this.props.app.hindi} toggleEvent={this.props.setParams}/>
      <div style={{ textAlign: "center" }}>
        <h3 style={{ margin: "6rem 2rem 1rem" }}>{isHindi?"रिपोर्टिंग और सभी के लिए सुरक्षा सुनिश्चित करने के लिए धन्यवाद":"Thanks for reporting and ensuring safety for all!"}</h3>
        <p style={{ margin: "1rem 2rem 0.5rem", fontSize: "0.875rem" }}>{isHindi?"आपकी रिपोर्ट कमांड सेंटर, और संबंधित लोगों को तत्काल कार्रवाई के लिए भेज दी गई है|":"Your report has been sent to the Command Center, & relevant people for action"}</p>
        <p style={{ margin: "1rem 2rem 0.5rem", fontSize: "0.875rem" }}>{isHindi?"ध्यान दें की आपकी पहचान गुप्त रहेगी|":"Note that your identity will not be disclosed."}</p>
        <p style={{ margin: "0rem 2rem", fontSize: "0.875rem", color: "#474747BD" }}>Reporting ID: {this.props.app.reportId}</p>
        <div className="action-btn-cntr" style={{ marginTop: "3rem", marginBottom: "5rem" }}>
          <Button variant="outlined" onClick={()=>{this.props.history.push('/');this.props.finishReport()}}>{isHindi?"समाप्त":"Finish"}</Button>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({ data: state.data, app: state.app });

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ finishReport, setParams }, dispatch);
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(Report);