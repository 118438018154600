import React from "react";
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Menu from '@material-ui/core/Menu';
import { hydrate } from "../redux/dataActions";
import { setParams } from "../redux/appStateActions";
import Header from "../../components/Header";

class setLocationPg extends React.Component {
  constructor(props) {
    super(props);
    this.state = { long: null, lat: null, location: 0 };
    if (!Object.keys(this.props.data.locations).length) { this.props.hydrate("locations") };
    if (!Object.keys(this.props.data.roles).length) { this.props.hydrate("roles") };
    if (!(this.props.app.location && Object.keys(this.props.app.location).length)) { this.props.hydrate("locations") };
    if (!(this.props.data.vehicleEvents && Object.keys(this.props.data.vehicleEvents).length)) { this.props.hydrate("vehicleEvents") };
    if (!(this.props.data.signals && Object.keys(this.props.data.signals).length)) { this.props.hydrate("signals") };

    this.handleClick = (ev) => {
      this.setState({ menuOpen: true, anchorEl: ev.currentTarget });
    }
    this.menuOpen = (ev) => {
      this.setState({ ...this.state, menuOpen: true });
    }
    this.handleClose = (id) => {
      if(id){
        this.props.data.locations[id].id = id;
        this.props.setParams({ target: "location", value: this.props.data.locations[id] });
      }
      this.setState({ ...this.state, menuOpen: false });
    }

    this.inputLabel = React.createRef();
    this.handleChange = event => {
      this.setState({ location: event.target.value })
    };
  }


  render() {
    let locations = this.props.data.locations;
    let location = this.props.app.location;
    let isHindi = this.props.app.hindi;
    let locationTitle = null;
    let locationTrigger = isHindi?"अपनी लोकेशन चुनें":"Choose your location"
    if(location){locationTitle = isHindi?location["title-hn"]:location.title;}
    return (<div className="pg-cntr v-center">
      <Header hindi={this.props.app.hindi} toggleEvent={this.props.setParams}/>
      <div className="location-main-cntr">        
        <div style={{ textAlign: "center" }}>
        {this.props.app.hindi?null:<p>I am at</p>}
          {/* {this.props.app.hindi?null:<p>I want to report a potential safety hazard at</p>} */}
          {Object.keys(location).length?<div className="location-title">{locationTitle}</div>:
          <div className="location-title" style={{margin:"1rem 0rem"}} id="location-menu-primary" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>{locationTitle?locationTitle:locationTrigger}</div>}
          <Menu
            id="location-menu-primary"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.menuOpen||false}
            onClose={()=>this.handleClose(null)}
          >
            {Object.keys(locations).map((id, i) => {
              let el = locations[id];
              return <MenuItem onClick={(ev) => this.handleClose(id)} value={id} key={id}>
                {this.props.app.hindi?el["title-hn"]:el.title}
              </MenuItem>
            })}
          </Menu>
          {/* {this.props.app.hindi?<p className="-hn">में एक संभावित खतरे की रिपोर्ट करना चाहता हूँ|</p>:null} */}
        </div>
        {/* {Object.keys(location).length?<div style={{ margin: "2rem" }}>
          <a className="action-btn" style={{ textDecoration: "none", display: "block" }} href="/setVehicleEvent">YES</a>
        </div>:null} */}

        {Object.keys(location).length?<div className="location-change">
          {isHindi?null:<span>Not at </span>} 
          <span id="location-menu" className="location-menu" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>{location?locationTitle : "... searching"}</span>
          {isHindi?<span> में नहीं हूँ?</span>:null}
          <Menu
            id="location-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.menuOpen||false}
            onClose={()=>this.handleClose(null)}
          >
            {Object.keys(locations).map((id, i) => {
              let el = locations[id];
              return <MenuItem onClick={(ev) => this.handleClose(id)} value={id} key={id}>
                {this.props.app.hindi?el["title-hn"]:el.title}
              </MenuItem>
            })}
          </Menu>
        </div>:null}
        <div className="routing-instruction">{isHindi?"मैं एक":"I want to"}</div>
        <div className="router-cntr">
        <div className="action-btn-blk" onClick={()=>{this.props.history.push("/setVehicleEvent")}}>
          {isHindi?"संभावित ख़तरे को रिपोर्ट करना चाहता हूँ":"report an Unsafe Condition"}
        </div>
        <div className="action-btn-blk" onClick={()=>{this.props.history.push("/nearMiss")}}>
          {isHindi?"नीयर मिस को लोग करना चाहता हूँ":"log a Near Miss"}
        </div>
        </div>
        
      </div>
    </div>)
  }

  componentDidMount() {
    this.props.hydrate("settings");
    this.props.hydrate("locations");
    this.props.hydrate("roles");
    this.props.hydrate("eventsCategory");
    this.props.hydrate("vehicleEvents");
    this.props.hydrate("signals");
  }
}

const mapStateToProps = state => ({ data: state.data, app: state.app });

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ hydrate, setParams }, dispatch);
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(setLocationPg);