import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../components/Header";
import { setParams } from "../redux/appStateActions";
import { postNearMiss, loadFile } from "../redux/dataActions";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Webcam from "react-webcam";
import Slide from '@material-ui/core/Slide';
import ImageUploader from 'react-images-upload';
import Button from '@material-ui/core/Button';

const mediaDimension = "300";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class NearMissReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cameraMsg: "Checking your Camera..." };
    this.missId = props.match.params.nmId;
    this.webcamRef = React.createRef();
    this.captureImage = (ev) => {
      this.props.setParams({ target: "image", value: this.webcamRef.current.getScreenshot() });
    }
    this.props.setParams({ target: "nearMissCategory", value: props.match.params.nmcId });
    this.props.setParams({ target: "nearMiss", value: props.match.params.nmId });
    this.onDrop = (picture) => {
      this.props.loadFile(picture[0], picture[0].name)
      this.props.setParams({ target: "image", value: picture });
    }
  }
  render() {
    console.log(this.props.app);
    let isHindi = this.props.app.hindi;
    let miss = this.props.data.nearMiss[this.missId];
    return <div className="pg-cntr">
      <div style={{ width: "100%" }}>
        <Header hindi={this.props.app.hindi} back={()=>this.props.history.push(`/nearMiss/${this.props.match.params.nmcId}`)} toggleEvent={this.props.setParams} />
        <div className="pg-cntr-no-header">
          <div>{miss.title}</div>
          <div className="camera-cntr">
              {this.state.settingCamera ? <div>Setting your Camera</div> : null}
              {this.props.app.image ? <img alt="media" width={mediaDimension} height={mediaDimension} src={this.props.app.image} /> : <Webcam
                onUserMedia={(el) => this.setState({ cameraMsg: isHindi ? "रिपोर्ट को विस्तृत करने के लिए एक तस्वीर जोड़ें|" : "Click a picture to report" })}
                onUserMediaError={(el) => this.setState({ cameraMsg: JSON.stringify(el) })}
                audio={false}
                height={mediaDimension}
                ref={this.webcamRef}
                screenshotFormat="image/jpeg"
                width={mediaDimension}
                videoConstraints={{
                  width: mediaDimension,
                  height: mediaDimension,
                  facingMode: "environment"
                }}
              />}
              <div className="action-btn-cntr camera-action" style={{ position: "absolute", bottom: 20 }}>
                {this.props.app.image
                  ? <div onClick={()=>this.props.setParams({ target: "image", value: null })}>{isHindi ? "ठीक न हो तो कैंसिल करें" : "Take a different Picture"}</div>
                  : <div onClick={this.captureImage} >{isHindi ? "तस्वीर लेने के लिए क्लिक करें |" : "Click to take a picture"}</div>}
              </div>
            </div>
            <div style={{textAlign:"center", marginTop: "1rem", fontWeight: "bold"}}>OR</div>
            <ImageUploader
                withIcon={false}
                buttonText={isHindi?'गैलरी से चुनें':'Choose from gallery'}
                onChange={this.onDrop}
                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                maxFileSize={52428800}
            />
            <div className="comment-cntr" style={{marginTop: "1rem"}} onClick={() => { this.props.setParams({ target: "commentBoxActive", value: true }) }}>
              {this.props.app.userComment || "Add a comment"}
            </div>
            <div className="action-btn-cntr">
              <Button variant="outlined" onClick={(ev) => { this.props.postNearMiss(this.props.app); this.props.history.push("/report") }}>
                {isHindi ? "रिपोर्ट करें" : "Submit"}
              </Button>
            </div>
        </div>
      </div>
      <Dialog classes={{ root: "usercomment-root" }} fullScreen open={this.props.app.commentBoxActive || false} TransitionComponent={Transition} onClose={() => this.props.setParams({ target: "commentBoxActive", value: false })}>
        <div style={{ padding: "2rem" }}>
          <form style={{ marginTop: "0px" }}>
            <TextField
              autoFocus={true}
              id="comment-signal"
              label="Add a comment"
              multiline
              defaultValue={this.props.app.userComment}
              margin="normal"
              rowsMax={6}
              fullWidth={true}
              className="text-area"
              onChange={(ev)=>{this.setState({tempComment:ev.target.value})}}
            />
          </form>
          <div className="action-btn-cntr" style={{justifyContent:"space-around"}}>
            <Button onClick={(ev) => { this.props.setParams({target:"commentBoxActive", value:false}) }}>
              {isHindi ? "रद्द करें":"Cancel"}
            </Button>

            <Button onClick={(ev) => {
              this.props.setParams({target:"userComment", value:this.state.tempComment}); 
              this.props.setParams({target:"commentBoxActive", value:false});
             }}>
              {isHindi ? "रिपोर्ट करें" : "Submit"}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  }
}

const mapStateToProps = state => ({ data: state.data, app: state.app });
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ setParams, postNearMiss, loadFile }, dispatch);
}
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(NearMissReport);