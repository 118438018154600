import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { hydrate, postReport, loadFile } from "../redux/dataActions";
import { setParams } from "../redux/appStateActions";
import Header from "../../components/Header";
import Slide from '@material-ui/core/Slide';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import AddCircleIcon from '@material-ui/icons/AddCircleRounded';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Button from '@material-ui/core/Button';

class SetSignals extends React.Component {
  constructor(props) {
    super(props);
    this.selectSignal = (sigId) => {
      this.props.setParams({ target: "signal", value: sigId })
      this.props.setParams({ target: "showConfirmation", value: true });
    }
  }
  render() {
    let associatedSignals = this.props.data.vehicleEvents[this.props.app.event] ? this.props.data.vehicleEvents[this.props.app.event].associatedSignals : {};
    this.props.app.showSignals = true;
    let isHindi = this.props.app.hindi;

    return <div className="pg-cntr">
      <div style={{ width: "100%" }}>
        <Header hindi={this.props.app.hindi} toggleEvent={this.props.setParams} back={()=>this.props.history.push("/setVehicleEvent")}/>
        <div className="pg-cntr-no-header">
          <div className="signal-cntr" style={{width:"100%"}}>
            <Slide direction="left" in={this.props.app.showSignals} mountOnEnter unmountOnExit>
              <div>
                {/* <div className="header-bar" onClick={()=>this.props.history.push("/setVehicleEvent")}>
                  <ChevronLeftIcon /> <span>{isHindi ? "पीछे" : "Back"}</span>
                </div> */}
                <div>
                  <div className="section-header">{isHindi ? "नीचे दिखाए गए चित्रों में से एक चुनें|" : "Are you seeing any one of these?"}</div>
                  <div className="associatedSignals">
                    {Object.keys(associatedSignals).length ? Object.keys(associatedSignals).map(signalId => {
                      let signal = this.props.data.signals[signalId];
                      return <Card classes={{ root: "signal-card" }} key={signalId} onClick={(ev) => {this.selectSignal(signalId); this.props.history.push("/takePhoto")}}>
                        <CardActionArea>
                          {signal.img ? <CardMedia
                            component="img"
                            width={330}
                            height={220}
                            image={signal.img}
                            title="Signal Image"
                          /> : null}
                          <CardContent classes={{ root: "signal-card-content" }} >
                            {isHindi ? signal["title-hn"] : signal.title}
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button size="small" color="primary">
                            {isHindi ? "चुनें" : "Report"}
                          </Button>
                        </CardActions>
                      </Card>
                    }) : <span>No Associated Signals Found</span>}
                    <div className="signal-card new-signal-trigger" onClick={(ev) => {this.selectSignal("newSignal"); this.props.history.push("/takePhoto")}}>
                      <AddCircleIcon color="primary" />
                      <div style={{ fontSize: "0.81rem" }}>Can't Find? Add a new signal</div>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({ data: state.data, app: state.app });

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ hydrate, setParams, postReport, loadFile }, dispatch);
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(SetSignals);