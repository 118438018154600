import React from "react";
import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import "./EventList.css"

const today = new Date();
let options = { year: 'numeric', month: 'short', day: 'numeric' };
class EventList extends React.Component{
  render(){
    let locations = this.props.data.locations;
    let signals = this.props.data.signals;
    let fprActions = this.props.data.settings.fprActions;
    let isHindi = this.props.app.hindi;
    return <div>
      {Object.keys(this.props.reports).map(reportId=>{
        let report = this.props.reports[reportId];
        let time = new Date(report.timestamp);
        let lastAction = report["lastAction"]?report.actions[report["lastAction"]]:null;
        let timeString;
        if(time.getDate()===today.getDate()){ timeString = time.toLocaleTimeString().replace(/:\d+ /, ' ');} 
        else { timeString = time.toLocaleDateString("en-US", options) }
        let action_ = report.actions&&report.actions["a-003"]?"a-003":(report.actions&&report.actions["a-002"]?"a-002":(report.actions&&report.actions["a-001"]?"a-001":"a-000"));
        let action = lastAction||action_;
        return <div key={reportId} className="report-blk">
          <div className="left-blk">
            <div className="dashboard-time">{timeString}</div>
            <div className="dashboard-location">{isHindi?locations[report.location]["title-hn"]:locations[report.location].title}</div>
            <div className="dashboard-title">{isHindi?signals[report.signal]["title-hn"]:signals[report.signal].title}</div>
            {report.userComment?<div style={{fontSize:"0.8rem"}}>{report.userComment}</div>:null}
          </div>
          <div className="right-blk">
            <div className={`indicator ${action}`}></div>
            <div className="dashboard-status">{fprActions[action]?fprActions[action].title:"Reported"}</div>
          </div>
        </div>
      })}
    </div>
  }
}

const mapStateToProps = state => ({ data: state.data, app: state.app });

export default connect(
  mapStateToProps,
  null
)(EventList);