import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
// import { Switch, Route, Redirect } from 'react-router';
import { connect } from "react-redux";

import SetProfile from "./containers/setProfile";
import SetReport from "./containers/setReport";
import SetVehicleEvent from "./containers/setVehicleEvent";
import SetLocation from "./containers/setLocation";
import History from "./containers/history";
import NearMiss from "./containers/nearMiss";
import NearMissCategories from "./containers/nearMissCategories";
import NearMissReport from "./containers/nearMissReport";
import TakePhoto from "./containers/takePhoto";
import Signals from './containers/setSignals';

class RouterComponent extends React.Component {
  render() {
    let userLoggedIn = Object.keys(this.props.app.user).length;
    let onReportPage = this.props.app.onReportPage;
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/report"
            render={props => (!userLoggedIn
              ? <Redirect to="/setProfile" />
              : <SetReport {...props} />)} />
          <Route path="/setVehicleEvent"
            render={props => (userLoggedIn
              ? <SetVehicleEvent {...props} />
              : <Redirect to="/setProfile" />)} />
          <Route path="/setProfile"
            render={props => (userLoggedIn
              ? <Redirect to="/SetLocation" />
              : <SetProfile {...props} />)} />
          <Route path="/SetLocation"
            render={props => (!userLoggedIn
              ? <Redirect to="/setProfile" />
              : <SetLocation {...props} />)}
          />
          <Route path="/History"
            render={props => (!userLoggedIn
              ? <Redirect to="/setProfile" />
              : <History {...props} />)}
          />
          <Route path="/nearMiss/:nmcId/:nmId"
            render={props => (!userLoggedIn
              ? <Redirect to="/setProfile" />
              : <NearMissReport {...props} />)}
          />
          <Route path="/nearMiss/:nmcId"
            render={props => (!userLoggedIn
              ? <Redirect to="/setProfile" />
              : <NearMiss {...props} />)}
          />
          <Route path="/takePhoto"
            render={props => (!userLoggedIn
              ? <Redirect to="/setProfile" />
              : <TakePhoto {...props} />)}
          />
          <Route path="/signals"
            render={props => (!userLoggedIn
              ? <Redirect to="/setProfile" />
              : <Signals {...props} />)}
          />
          <Route path="/nearMiss"
            render={props => (!userLoggedIn
              ? <Redirect to="/setProfile" />
              : <NearMissCategories {...props} />)}
          />
          <Route path="/"
            render={props => (userLoggedIn
              ? <Redirect to="/SetLocation" />
              : <Redirect to="/setProfile" />)} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({ data: state.data, app: state.app });

export default connect(
  mapStateToProps,
  null
)(RouterComponent);