import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../components/Header";
import { setParams } from "../redux/appStateActions";
import { hydrate } from "../redux/dataActions";

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

class NearMissCat extends React.Component {
  render() {
    console.log(this.props.app);
    let isHindi = this.props.app.hindi;
    let nearMissCategories = this.props.data.nearMissCategories || {};
    return <div className="pg-cntr">
      <div style={{ width: "100%" }}>
        <Header hindi={this.props.app.hindi} back={()=>this.props.history.push("/setLocation")} toggleEvent={this.props.setParams} />
        <div className="pg-cntr-no-header">
          <div style={{padding:"1rem 0rem"}}>Choose a near miss category here</div>
          {Object.keys(nearMissCategories).map(nmcId => {
            let category = nearMissCategories[nmcId];
            category.associatedNearMisses = category.associatedNearMisses || {};
            return <div onClick={(ev) => { this.props.history.push(`./nearMiss/${nmcId}`) }} className="nm-category-cntr" key={nmcId} style={{ order: category.order || 0 }} >
              <div className="nm-category-icon"><img alt="event-cat" src={category.icon} /></div>
              <div className="nm-category-title">{isHindi ? category["title-hn"] : category.title}</div>
              <ChevronRightIcon />
            </div>;
          })}
        </div>
      </div>
    </div>
  }

  componentDidMount() {
    this.props.hydrate("nearMissCategories");
    this.props.hydrate("nearMiss");
    // this.props.hydrate("locations");
  }
}

const mapStateToProps = state => ({ data: state.data, app: state.app });
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ setParams, hydrate }, dispatch);
}
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(NearMissCat);